<template>
	<div @click="handlePulledUp" class="overlay" :class="{ 'visible': pulledUp }" />
	<section class="card" :class="{ 'pulled-up': pulledUp }" >
		<div class="grab-bar"
			@click="handlePulledUp"
			/>

		<div class="welcome" v-if="!pulledUp">

			<div class="info-side">
				<h2>Good Afternoon, {{ user.fname }} {{ user.lname }} #{{ user.id }}</h2>	
				<p class="shadow-info">Your Job Board Rating: <strong>{{ user.rating }}/5</strong></p>
				<p class="shadow-info">Last Job Ended: <strong>{{ user.lastJobEnded }}</strong></p>
				<p class="shadow-info">Job Status: <strong>{{ user.jobStatus }}</strong></p>
			</div>

			<div class="button-side">
				<button class="btn" @click="$emit('findMe')">Find Me</button>
				<button class="btn orange" @click="pulledUp= true">Find Job</button>
			</div>
		</div>
		
		<List v-if="pulledUp" @selectLocation="selectLocationPass" />
		
	</section>
</template>

<script setup>
	import List from '@/components/List.vue'
	import { defineEmits, ref, watch } from 'vue'
	import { useStore } from 'vuex'

	const emit = defineEmits(['selectLocation'])
	const store = useStore()

	function selectLocationPass(e) {
		pulledUp.value = false;
		dragDiff.value = 70
		emit('selectLocation', e)
	}

	function handlePulledUp() {
		if(pulledUp.value){
			store.commit('setCurrentlyOpenedMarker', null)
			pulledUp.value = false
		}else{
			pulledUp.value = true
		}
	}


	const pulledUp = ref(false);

	const user = store.state.user

	//Watches for changes to the currentlyOpenedMarker store

	watch(
		() => store.state.currentlyOpenedMarker,
		() => {
			if(store.state.currentlyOpenedMarker) pulledUp.value = true
		}
	)
	
</script>


<style scoped>
.card{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80vh;
	background: #fff;
	box-shadow: 0 -3px 10px rgb(0 0 0 / 20%);
	border-radius: 2rem 2rem 0 0;
	overflow-y: scroll;
	padding: 1.5rem;
	padding-top: 0;
	z-index: 10;
	transform: translateY(70%);
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
	transition: transform 100ms;
}

.card.pulled-up{
	transform: translateY(0);
}

@media screen and (min-width: 550px){
	.card{
		max-width: 500px;
	}
}

.scroll-body{
	display: block;
	width: 100%;
	height: auto;
	overflow-y: scroll;
}

.overlay{
	position: fixed;
	inset: 0;
	background: rgb(0 0 0 / 50%);
	z-index: 9;
	opacity: 0;
	pointer-events: none;
	transition: opacity 300ms;
}

.overlay.visible{
	pointer-events: auto;
	opacity: 1;
}

.grab-bar{
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	background: #fff;
}

.grab-bar::before{
	content: '';
	width: 40px;
	height: 5px;
	background: var(--gray);
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 10px;
}

.welcome{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
}

h2{
	font-size: 1rem;
	text-transform: uppercase;
}

.shadow-info{
	font-size: 0.7rem;
	color: var(--gray);
	text-transform: uppercase;
}

.btn{
	width: 100%;
	margin-bottom: 15px;
}

</style>