<template>
		
			<div class="overlay" @click="$emit('closeModal')" />
			<section class="modal" :class="{ 'confirmed': $store.state.confirmedJob }">
				<header>
					<h3 v-if="!$store.state.confirmedJob">Confirm Your Selection</h3>
					<h3 class="confirmed" v-else>JOB SELECTION CONFIRMED - <span class="green-txt">THANK YOU</span></h3>
					<button v-if="!$store.state.confirmedJob" @click="$emit('closeModal')">&times;</button>
				</header>
				<section class="top-grid" ref="topGrid">
					<BlackBox :id="marker.id" />
					<p><strong>{{marker.distance}}</strong> <span>Miles Away</span></p>
					<p><strong>{{ marker.hours }}</strong> <span>EST. HOURS</span></p>
					<p><strong>{{ marker.startTime }}</strong> <span>START TIME</span></p>
				</section>
				<section class="bottom-section" ref="bottomSection" :style="`--bottom-section-height: ${bottomSectionHeight};`">
					<div class="measure-box" ref="measureBox">
						<p class="label">JOB DETAIL:</p>
						<h3>{{ marker.order }}</h3>
						<div class="col-2">
							<div>
								<p class="label">LOCATION:</p>
								<p><strong>{{ marker.address }}</strong></p>
							</div>
							<div>
								<p class="label">JOB:</p>
								<p><strong>{{ marker.jobType }}</strong></p>
							</div>
						</div>
					</div>
				</section>
				<section class="clock-and-confirm">
					<h4 class="clock">THIS JOB BEGINS IN: {{ days > 0 ? `${days}:`: '' }}{{ hours }}:{{ minutes }}:{{ seconds }}</h4>
					<button class="green btn" v-if="!$store.state.confirmedJob" @click="confirmJob">Confirm Accept Job</button>
					<button class="green btn" v-if="$store.state.confirmedJob" @click="closeModal">Close</button>
				</section>
		
				<transition name="loader-toggle">
					<div class="loading-overlay" v-if="loading">
						<div class="loader"></div>
					</div>
				</transition>
			</section>

</template>

<script>
import BlackBox from './BlackBox.vue'
import { mapGetters } from 'vuex';

export default {
	name: 'Modal',
	components:{
		BlackBox
	},
	data() {
		return {
			confirmed: false,
			loading: false,
			showClosed: true,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		}
	},
	mounted(){
		setInterval(() => {
			this.countdown()
			
		}, 1000);
	},
	computed: {
		...mapGetters({
			marker: 'getCurrentMarker',
		})
	},
	methods: {
		confirmJob(){
			this.loading = true
			const startDate = Number(new Date(`${this.marker.orderDate} ${this.marker.startTime}`))
			
			window.localStorage.setItem('confirmedDate', startDate)
			window.localStorage.setItem('confirmedId', this.marker.id)
			
			setTimeout(() => {
				this.$store.commit('confirmJob', this.marker.id)
				this.loading = false
				this.confirmed = true
			}, 1000);
		},
		countdown(){
			const now = new Date().getTime();
			const startDate = Number(new Date(`${this.marker.orderDate} ${this.marker.startTime}`))
			const distance = startDate - now;
			this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
			this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
		},
		closeModal(){
			this.$store.commit('confirmJob', null)
			this.confirmed = false
			this.$store.state.confirmedJob = null
			this.$store.state.currentlyOpenedMarker = null
			this.$store.state.isModalOpen = false
			window.localStorage.removeItem('confirmedDate')
			window.localStorage.removeItem('confirmedId')
		}
	},
}
</script>

<style scoped>

.overlay{
	position: fixed;
	inset: 0;
	background: rgb(0 0 0 / 50%);
	z-index: 101;
}

.modal{
	padding: 1rem 1rem 2.5rem;
	background: #fff;
	position: fixed;
	left: 50%;
	top: 50%;
	border-radius: 1rem;
	width: calc(100% - 40px);
	max-width: 450px;
	transform: translate(-50%, -50%);
	z-index: 102;
	box-shadow: 0 0 20px rgb(0 0 0 / 10%), 3px 3px 10px rgb(0 0 0 / 25%);
	transition: all 400ms;
}

.modal.confirmed{
	inset: 0;
	transform: none;
	width: 100%;
	border-radius: 0;
	max-width: none;
}
header{
	border-bottom: solid 1px #000;
	padding: 1rem 0;
}

header h3{
	margin: 0;
	text-transform: uppercase;
	padding-right: 60px;
}

h3 .green-txt{
	color: var(--green);
}

header h3.confirmed{
	padding-right: 0;
}

header button{
	flex: 0 0 55px;
	background: none;
	color: var(--gray);
	font-size: 2rem;
	display: flex;
	border: none;
	align-items: center;
	width: 48px;
	justify-content: center;
	position: absolute;
	top: 18px;
	right: 1rem;
	cursor: pointer;
}

header button:hover{
	color: #e24545;
}

.top-grid{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 10px;
		align-items: center;
		margin-top: 1rem;
	}

	.black-box{
		background: #000;
		color: #fff;
		border: none;
		border-radius: 10px;
		height: 45px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	p{
		text-align: center;
		margin: 0;
	}

	p span{
		color: var(--gray);
		font-size: 0.7rem;
		display: block;
		text-transform: uppercase;

	}
	

.bottom-section{
	text-align: left;
	padding: 1rem 0 0;
	height: var(--bottom-section-height, auto);
	overflow: hidden;
	transition: height 300ms;
}

.bottom-section p{
	text-align: left;
	margin: 0;
}

.bottom-section h3{
	margin: 0;
	margin-bottom: 10px;
}

.bottom-section .label{
	font-size: 0.7rem;
	color: var(--gray);
}

.col-2{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	margin-bottom: 15px;
}

.col-2:last-child{
	margin-bottom: 0;
}

.clock-and-confirm{
	padding-top: 1rem;
	margin-top: 1rem;
	border-top: solid 1px #000;
}

h4{
	text-align: center;
	margin: 0 0 15px;
	font-size: 1.3rem;
	color: var(--gray);
}

h4.confirmed{
	color: #000;
}

.loading-overlay{
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(255 255 255 /70%);
	z-index: 10;
	border-radius: 1rem;
}

.loader{
	width: 70px;
	height: 70px;
	border: solid 5px transparent;
	border-top: solid 5px #000;
	border-bottom: solid 5px #000;
	border-radius: 100px;
	animation: loading 1s infinite;
}

@keyframes loading {
	from{ transform: rotate(0turn); }
	to{ transform: rotate(1turn); }
}

.loader-toggle-enter-active,
.loader-toggle-leave-active {
  transition: opacity 200ms ease;
}

.loader-toggle-enter-from,
.loader-toggle-leave-to {
  opacity: 0;
}



</style>