<template>
  <img src="/logo.png" alt="logo" class="map-logo">
  <GMapMap
    :center="center"
    :zoom="11"
    map-type-id="roadmap"
    style="width: 100vw; height: 50vh"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    }"
  >
    <GMapMarker
      :position="current"
      :clickable="true"
      :draggable="false"
      @click="center = current"
    >
    </GMapMarker>

    <GMapMarker
      :key="index"
      v-for="(m, index) in store.state.markers"
      :position="m.position"
      :clickable="true"
      :draggable="false"
      @click="handleMarkerClick(m)"
      :icon="markerIcon"
    >
    </GMapMarker>

  </GMapMap>

  <!-- <button @click="geoFindMe">Find Me</button> -->


  <Card @selectLocation="centerMapToPin" @findMe="geoFindMe" />

  	<teleport to="body">
		
		<Modal v-if="store.state.isModalOpen" @closeModal="store.state.isModalOpen = null" />
		
	</teleport>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from 'vuex'
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";

const markerIcon = {      
  url: require('@/assets/cone.png'),
  scaledSize: { width: 42, height: 42 }
}

const store = useStore()

const currentOpenPin = ref(null)

const current = reactive({
  lat: 40.26554557515963,
  lng: -76.74469405983984,
});

const currentPositionLocationMethod = ref('Current Position')

const center = reactive({ lat: 40.26554557515963, lng: -76.74469405983984 });

onMounted(() => {
  if(window.localStorage.getItem('confirmedDate') > Number(new Date())){
    const id = parseInt(window.localStorage.getItem('confirmedId'))
    store.state.confirmedJob = id
    store.commit('setCurrentlyOpenedMarker', id) 
    store.state.isModalOpen = true
  }else{
    window.localStorage.removeItem('confirmedDate')
    window.localStorage.removeItem('confirmedId')
  }

  findDistances({currentLat: current.lat, currentLng: current.lng})

})

function geoFindMe() {
  function success(position) {
    const { latitude, longitude } = position.coords;

    center.lat = latitude;
    center.lng = longitude;

    current.lat = latitude;
    current.lng = longitude;

    findDistances({currentLat: latitude, currentLng: longitude})
  }

  function error(err) {
    console.error("whoops", err);
  }

  navigator.geolocation.getCurrentPosition(success, error);
}

function handleMarkerClick({ position, id }) {
  center.lat = position.lat;
  center.lng = position.lng;

  store.commit('setCurrentlyOpenedMarker', id)

  if(currentOpenPin.value == id) {
    currentOpenPin.value = null;
  } else {
    currentOpenPin.value = id
  }
}

function closeMarkerInfo() {
  currentOpenPin.value = null;
}

function centerMapToPin({lat, lng, id}) {
  center.lat = lat
  center.lng = lng

  currentOpenPin.value = id
}

function findDistances({currentLat, currentLng}) {
  const newMarkers = store.state.markers.map(marker => {
    const R = 3958.8
    const { lat, lng } = marker.position
    

    const rlat1 = currentLat * (Math.PI/180)
    const rlat2 = lat * (Math.PI/180)

    const diffLat = rlat2-rlat1
    const diffLng = (lng - currentLng) * (Math.PI/180)

    const distance = 2 * R * Math.asin(Math.sqrt(Math.sin(diffLat/2)*Math.sin(diffLat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(diffLng/2)*Math.sin(diffLng/2)))


    marker.distance = distance.toFixed(2)
    
    return marker
  })

  store.markers = newMarkers
}


</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700;900&display=swap');
  body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }

  *{
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  :root{
    --gray: #717171;
    --lightgray: #B7B7B7;
    --green: #1e7b01;
    --orange: #E95F00;
  }
.vue-map-container {
  height: 80vh;
  width: 100%;
}

.btn{
	width: 100%;
	background: var(--gray);
	color: #fff;
	border: none;
	font-size: 1.2rem;
	padding: .75rem 1rem;
	border-radius: 0.5rem;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: bold;
}

.btn.green{
	background: var(--green);
}
.btn.orange{
	background: var(--orange);
}

.map-logo{
  position: fixed;
  z-index: 10;
  width: 100px;
  top: 1rem;
  left: 1rem;
}
</style>
