<template>
	<li>
		<div class="drawer" ref="drawer" :id="`row-${marker.id}`">
			<section class="top-grid" ref="topGrid" @click.stop="store.commit('setCurrentlyOpenedMarker', marker.id)">
				<BlackBox :id="marker.id" />
				<p><strong>{{marker.distance}}</strong> <span>Miles Away</span></p>
				<p><strong>{{ marker.hours }}</strong> <span>EST. HOURS</span></p>
				<p><strong>{{ marker.startTime }}</strong> <span>START TIME</span></p>
			</section>
			<section class="bottom-section" ref="bottomSection" :style="`--bottom-section-height: ${bottomSectionHeight};`">
				<div class="measure-box" ref="measureBox">
					<p class="label" style="margin-top: 1rem;">JOB DETAIL:</p>
					<h3>Order {{ marker.order }} &mdash; {{ dateMe(marker.orderDate) }}</h3>
					<p class="label">LOCATION:</p>
					<p><strong>{{ marker.address }}</strong></p>					
					<div class="col-2">
						<div>
							<p class="label">CLIENT CONTACT:</p>
							<p class="client">{{ marker.clientName }}</p>
							<p><strong>{{ marker.clientSupervisor }}</strong></p>
							<p><strong>{{ marker.clientPhone }}</strong></p>
						</div>
						<div>
							<p class="label">JOB:</p>
							<p><strong>{{ marker.jobType }}</strong></p>
							<p class="label">CREW ROLE STATUS:</p>
							<p v-for="(crew, type) in marker.crewRoles" class="crew">
								<strong class="type">{{ type }}:</strong>
								<strong>{{ crew }}</strong>
							</p>
						</div>
					</div>
					<div class="center">
						<button class="btn green" @click="store.state.isModalOpen = true">Accept</button>
					</div>
				</div>
			</section>
		</div>
	</li>

</template>

<script setup>
import Modal from '@/components/Modal.vue'
import BlackBox from '@/components/BlackBox.vue'
import { defineProps, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'

	const props = defineProps( {
		marker: Object
	})
	const isOpen = ref(false)
	const bottomSectionHeight = ref('0px')
	const measureBox = ref(null)
	const bottomSection = ref(null)

	
	function openDrawer(){
		if(isOpen.value){
			closeDrawer()
		}else{
			const measureBoxHeight = measureBox.value.getBoundingClientRect().height;
			bottomSectionHeight.value = `${measureBoxHeight}px`
			// bottomSection.value.addEventListener('transitionend', () => {
			// 	bottomSectionHeight.value = `auto`
			// 	location.hash=`row-${props.marker.id}`
			// }, { once: true })

			setTimeout(() => {
				bottomSectionHeight.value = `auto`
				location.hash=`row-${props.marker.id}`
			}, 350);

			isOpen.value = true
		}
	}

	function handleCloseModal(){
		store.state.isModalOpen = false 
		store.commit('setCurrentlyOpenedMarker', null)
	}

	function closeDrawer(){
		const	measureBoxHeight = measureBox.value.getBoundingClientRect().height;
		bottomSectionHeight.value = `${measureBoxHeight}px`

		setTimeout(() => {
			bottomSectionHeight.value = `0px`
		}, 10);
		isOpen.value = false
	}

	const store = useStore()

	watchEffect(() => {
		if(measureBox.value && store.state.currentlyOpenedMarker === props.marker.id){
			openDrawer()
		}else if(measureBox.value && store.state.currentlyOpenedMarker !== props.marker.id){
			closeDrawer()
		}
	})


	function dateMe(date) {
			const hey = new Date(date);
			return hey.toDateString();
	}

</script>

<style scoped>

	li{
		margin: 5px 0;
		border-bottom: solid var(--gray) 2px;
		padding: 1rem 0;
	}
	
	.top-grid{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 10px;
		align-items: center;
		cursor: pointer;
	}

	.black-box{
		background: #000;
		color: #fff;
		border: none;
		border-radius: 10px;
		height: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	p{
		text-align: center;
		margin: 0;
	}

	p span{
		color: var(--gray);
		font-size: 0.7rem;
		display: block;
		text-transform: uppercase;

	}
	

.bottom-section{
	text-align: left;
	height: var(--bottom-section-height, auto);
	overflow: hidden;
	transition: height 300ms;
}

.bottom-section p{
	text-align: left;
	margin: 0;
}

.bottom-section h3{
	margin: 0;
	margin-bottom: 10px;
}

.bottom-section .label{
	font-size: 0.7rem;
	color: var(--gray);
	font-weight: 300;
}

.col-2{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	margin: 15px 0;
}

.col-2:last-child{
	margin-bottom: 0;
}

.circle{
	width: 25px;
	height: 25px;
	border: solid 2px #fff;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dot{
	width: 10px;
	height: 10px;
	border-radius: 100px;
}

.dot.active{
	background: #fff;
}

.crew { text-transform: uppercase; }
.crew .type {
	display: inline-block;
	width: 3rem;
	font-style: italic;
}
.client {
	font-weight:  bold;
	color:  var(--orange);
}
</style>