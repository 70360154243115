import { createStore } from "vuex";

export default createStore({
	state:{
		user: {
			fname: 'Joe',
			lname: 'Dokes',
			id: '007',
			rating: '4.5',
			lastJobEnded: '14:00',
			jobStatus: 'Complete'
		},
		markers: [
			{
				id: 1,
				position: {
					lat: 40.03808,
					lng: -76.300631,
				},
				message: "Site 1",
				infoOpened: false,
				startTime: "06:00",
				hours: 8.5,
				order: "123456",
				jobType: "Work Zone",
				address: "123 Any Street, Lancaster PA 17603",
				confirmedAsWorking: false,
				clientName: "Jimbo & Jambo",
				orderDate: "2021-12-17",
				clientSupervisor: "Jim Tester",
				clientPhone: "7175178383",
				crewRoles: {
					acl: "filled",
					cl: "filled",
					cm: "open"
				}
			},
			{
				id: 2,
				position: {
					lat: 40.044914,
					lng: -76.284828,
				},
				message: "Site 2",
				infoOpened: false,
				startTime: "06:00",
				hours: 7.5,
				order: "424242",
				jobType: "Work Zone",
				address: "123 Any Street, Lancaster PA 17603",
				confirmedAsWorking: false,
				clientName: "Jimbo & Jambo",
				orderDate: "2021-12-17",
				clientSupervisor: "Jim Tester",
				clientPhone: "7175178383",
				crewRoles: {
					acl: "filled",
					cl: "filled",
					cm: "open"
				}
			},
			{
				id: 3,
				position: {
					lat: 40.037768,
					lng: -76.318058,
				},
				message: "Site 3",
				infoOpened: false,
				startTime: "07:00",
				hours: 5.5,
				order: "99999",
				jobType: "Work Zone",
				address: "123 Any Street, Lancaster PA 17603",
				confirmedAsWorking: false,
				clientName: "Jimbo & Jambo",
				orderDate: "2021-12-17",
				clientSupervisor: "Jim Tester",
				clientPhone: "7175178383",
				crewRoles: {
					acl: "filled",
					cl: "filled",
					cm: "open"
				}
			},
			{
				id: 4,
				position: {
					lat: 40.16621533112741,
					lng: -76.58445949746176
				},
				message: "Site 4",
				infoOpened: false,
				startTime: "06:00",
				hours: 5,
				order: "555555",
				jobType: "Work Zone",
				address: "123 Any Street, Lancaster PA 17603",
				confirmedAsWorking: false,
				clientName: "Jimbo & Jambo",
				orderDate: "2021-12-17",
				clientSupervisor: "Jim Tester",
				clientPhone: "7175178383",
				crewRoles: {
					acl: "filled",
					cl: "filled",
					cm: "open"
				}
			},
			{
				id: 5,
				position: {
					lat: 40.26758257624137,
					lng: -76.68271235514995
				},
				message: "Site 5",
				infoOpened: false,
				startTime: "05:00",
				hours: 7.5,
				order: "8675309",
				jobType: "Work Zone",
				address: "123 Any Street, Lancaster PA 17603",
				confirmedAsWorking: false,
				clientName: "Jimbo & Jambo",
				orderDate: "2021-12-17",
				clientSupervisor: "Jim Tester",
				clientPhone: "7175178383",
				crewRoles: {
					acl: "filled",
					cl: "filled",
					cm: "open"
				}
			},
			{
				id: 6,
				position: {
					lat: 40.196243594753874,
					lng: -76.73260699967545
				},
				message: "Site 6",
				infoOpened: false,
				startTime: "06:30",
				hours: 7.5,
				order: "654321",
				jobType: "Work Zone",
				address: "123 Any Street, Lancaster PA 17603",
				confirmedAsWorking: false,
				clientName: "Jimbo & Jambo",
				orderDate: "2021-12-17",
				clientSupervisor: "Jim Tester",
				clientPhone: "7175178383",
				crewRoles: {
					acl: "filled",
					cl: "filled",
					cm: "open"
				}
			},  
		],
		confirmedJob: null,
		currentlyOpenedMarker: null,
		isModalOpen: false,
		sortedMarkers: null,
	},
	mutations:{
		confirmJob: (state, id) => state.confirmedJob = id,
		setCurrentlyOpenedMarker: (state, id) => {
			if(state.currentlyOpenedMarker !== id){
				state.currentlyOpenedMarker = id
			}else{
				state.currentlyOpenedMarker = null
			}
		},
		filterMarkers: (state, { column, order }) => {
			if(column === 'distance'){
				state.sortedMarkers = [...state.markers].sort((a, b) => {
					if( order === 'ASC' ) return a.distance - b.distance
					if( order === 'DESC' ) return b.distance - a.distance
				})
			}else if( column === 'hours' ){
				state.sortedMarkers = [...state.markers].sort((a, b) => {
					if( order === 'ASC' ) return a.hours - b.hours
					if( order === 'DESC' ) return b.hours - a.hours
				})
			}else if( column === 'startTime' ){
				state.sortedMarkers = [...state.markers].sort((a, b) => {
					const [hourA, minA] = a.startTime.split(':').map( item => item / 1 )
					const [hourB, minB] = b.startTime.split(':').map( item => item / 1 )

					let bool = 1

					if( (hourA < hourB) || (hourA === hourB && minA < minB) ){
						bool = -1
					}else if( hourA === hourB && minA === minB ){
						bool = 0
					}

					if( order === 'DESC' ) bool *= -1

					return bool
				})
			}else{
				state.sortedMarkers = null
			}
		}
	},
	getters: {
		getCurrentMarker: (state) => {
			if(state.currentlyOpenedMarker === null) return null

			const marker = state.markers.find( marker => marker.id === state.currentlyOpenedMarker )

			return marker
		}
	}
})