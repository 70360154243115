<template>
	<div class="parent">
		<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5 4L8 1L11 4" stroke="black" stroke-linecap="round" stroke-linejoin="round" 
				:class="{ active: upArrow, fade: downArrow }"
				class="up-arrow"/>
			<path d="M11 8L8 11L5 8" stroke="black" stroke-linecap="round" stroke-linejoin="round" 
				:class="{ active: downArrow, fade: upArrow }"
				class="down-arrow" />
		</svg>

	</div>
</template>

<script>
export default {
	props: {
		upArrow: {
			type: Boolean,
			default: false
		},
		downArrow: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>

.parent{
	position: absolute;
	right: 0.1rem;
	top: 50%;
	transform: translateY(-50%);

	path{
		stroke: #000;
		transition: all 300ms;
		transform-origin: center center;
	}

	.active{
		stroke: var(--orange);
		&.up-arrow{
			transform: translateY(8px) scale(2);
		}

		&.down-arrow{
			transform: translateY(-8px) scale(2);
		}
	}

	.fade {
		opacity: 0;
		&.up-arrow{
			transform: translateY(-8px) scale(2);
		}

		&.down-arrow{
			transform: translateY(8px) scale(.1);
		}
	}
}

</style>