import { createApp } from "vue";
import App from "./App.vue";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import store from './store'

const app = createApp(App);

app
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCs1_7pVV2Ts4mG2raDP--pFmu0xN0tSMo",
    },
  })
  .use(store)
  .mount("#app");
