<template>
	<h2>UPCOMING JOBS</h2>
	<p class="shadow-info">AVAILABLE JOBS BELOW ARE BEING SCHEDULED FOR THE FOLLOWING DAY</p>
	<div class="label-row">
		<p>Select</p>

		<button 
			class="filter-btn"
			@click="handleFilterClick('distance')" 
			:class="{ active: filter.column === 'distance'}">
				Distance
				<FilterArrows 
					:upArrow="filter.column === 'distance' && filter.order === 'ASC'"
					:downArrow="filter.column === 'distance' && filter.order === 'DESC'"
				/>
		</button>

		<button 
			class="filter-btn"
			@click="handleFilterClick('hours')" 
			:class="{ active: filter.column === 'hours'}">
				Hours
				<FilterArrows 
					:upArrow="filter.column === 'hours' && filter.order === 'ASC'"
					:downArrow="filter.column === 'hours' && filter.order === 'DESC'"
				/>
		</button>

		<button 
			class="filter-btn"
			@click="handleFilterClick('startTime')" 
			:class="{ active: filter.column === 'startTime'}">
				Start
				<FilterArrows 
					:upArrow="filter.column === 'startTime' && filter.order === 'ASC'"
					:downArrow="filter.column === 'startTime' && filter.order === 'DESC'"
				/>
		</button>

	</div>
  <ul>
    <ListItem v-for="marker in store.state.sortedMarkers || store.state.markers" :key="marker.id" :marker="marker" />
  </ul>
</template>

<script setup>
import { defineEmits, reactive } from "vue";
import { useStore } from 'vuex'
import ListItem from '@/components/ListItem.vue'
import FilterArrows from '@/components/FilterArrows.vue'

const store = useStore()

const emit = defineEmits(['selectLocation'])

const filter = reactive({
	column: null,
	order: null
})

function handleClick({lat, lng}, id) {
	emit('selectLocation', { lat, lng, id })
}

function handleFilterClick(newColumn) {
	if(newColumn === filter.column && filter.order === 'DESC'){
		filter.column = null
		filter.order = null
	}else if(filter.column === newColumn && filter.order === 'ASC'){
		filter.order = 'DESC'
	}else{
		filter.column = newColumn
		filter.order = 'ASC'
	}

	store.commit('filterMarkers', filter)
}
</script>


<style lang="scss">
	h2{
		margin: 0;
	}
	.shadow-info{
		font-size: 0.7rem;
		color: var(--gray);
		text-transform: uppercase;
		text-align: left;
	}
	
	ul{
		padding: 0;
		list-style: none;
		margin: 0;
	}

	.label-row{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 10px;
		font-size: .7rem;
		text-align: center;
		position: sticky;
		top: 49px;
		background: #fff;
		padding: 0px 0 10px;
		margin-top: 2rem;

		
		button, p{
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 0.7rem;
			background: none;
			border: none;
			padding: .5rem 1rem;
			position: relative;
			border-bottom: solid 2px transparent;
			transition: background 300ms;

			&.active{
				border-bottom-color: var(--orange);
			}
		}
	}

</style>
