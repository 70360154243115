<template>
	<div class="black-box" :class="{ active: store.state.confirmedJob === id }">
		<img src="/cone.png" alt="cone">
	</div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
	id: Number
})
</script>

<style scoped>

.black-box{
	background: #000;
}

.black-box.active{
	background: #FEDD00;
}

img{
	width: 25px;
}

.black-box.active img{
	filter: brightness(0) saturate(100%);
}

</style>